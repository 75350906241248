import * as React from "react";
import { graphql } from "gatsby";
import {withLayout} from "../components/Layout";

const MusicPage = (props: any) => {
  console.log(props);
  const music = props.data.music.nodes;

  return (
    <div className="pa4">
      <h1>Musik</h1>
      {
        music.map((song, i) => (
          <div className="">
            <h3>{song.title}</h3>
          <audio
            className="w-100 w-50-l w-80-m"
            controls
            src={song.mediaFile.publicURL}
            type="audio/mpeg"
            preload="metadata"
          />
          </div>
        ))
      }
      <p className="cf" />
    </div>
  );
};

export default withLayout(MusicPage);

export const pageQuery = graphql`
query PublishedMusicQUery {
  music: allStrapiMusic(filter: {published: {eq: true}}) {
    nodes {
      title
      mediaFile {
        publicURL
      }
    }
  }
}
`;
